<template>
  <div style="margin: -15px -15px;background-color: #f0f2f5;">
    <a-spin :spinning="spinning">
      <!-- tab切换 -->
      <div class="video-tab" style="background-color: #fff;margin-bottom: 20px;">
        <a-tabs v-model="tabValue">
          <!-- <a-tabs v-model="tabValue" @change="callback"> -->
          <a-tab-pane key="1" tab="按内容查询"></a-tab-pane>
          <a-tab-pane key="2" tab="按评论查询"></a-tab-pane>
        </a-tabs>
      </div>
      <!--按内容查询按钮 和 按评论查询按钮 -->
      <div class="video-top" v-if="tabValue === '1'" style="background-color: #fff; width: 100%;">
        <div style="transform: translateX(-17%);width: 48%;display: flex;justify-content: space-between">
          <div>
            <span>板块：</span>
            <a-select style="width: 150px;" class="input" v-model="stateType" placeholder="请选择" @change="typeChange">
              <a-select-option v-for="type in stateTypeList" :value="type.state">
                {{ type.title }}
              </a-select-option>
            </a-select>
          </div>

          <div>
            <span>名称：</span>
            <a-input style="width: 200px;" class="input" placeholder="请输入内容标题关键字" @keyup.enter.native="searchBtn()"
              v-model="searchName">
              <a-select-option v-for="type in stateTypeList" :value="type.state">
              </a-select-option>
            </a-input>
          </div>
        </div>
        <div>
          <a-button type="primary" @click="searchBtn">查询</a-button>
          <a-button style="margin-left: 15px" @click="returnBtn">重置</a-button>
        </div>
      </div>
      <div class="video-top" v-if="tabValue === '2'" style="background-color: #fff; width: 100%;">
        <div style="transform: translateX(-13%);width: 48%;display: flex;justify-content: space-between">
          <div>
            <span>板块：</span>
            <a-select style="width: 150px;" class="input" v-model="stateType2" placeholder="请选择" @change="typeChange2">

              <a-select-option v-for="type in stateTypeList2" :value="type.state">
                {{ type.title }}
              </a-select-option>
            </a-select>
          </div>
          <div>
            <span>内容：</span>
            <a-input style="width: 200px;" class="input" placeholder="请输入评论内容" @keyup.enter.native="search2Btn()"
              v-model="search2Name">
              <a-select-option v-for="type in stateTypeList" :value="type.state">
              </a-select-option>
            </a-input>
          </div>
        </div>
        <div>
          <a-button type="primary" @click="search2Btn">查询</a-button>
          <a-button style="margin-left: 15px" @click="return2Btn">重置</a-button>

          <a-popconfirm cancel-text="否" ok-text="是" title="是否删除这些数据?" @confirm="delConfirm">
            <a-button style="margin-left: 15px">删除</a-button>
          </a-popconfirm>
        </div>
      </div>

      <div class="HotContainer" v-if="tabValue === '1'" style="background-color: #fff;">
        <!-- 按内容查询框 -->
        <a-spin :spinning="spinning">
          <div class="hotTable">
            <a-table :columns="tableColumns" :data-source="tableData" :pagination="false"
              :row-key="(record) => record.id">

              <!-- tag标签 -->
              <span slot="titles" slot-scope="text,item">
                <!-- {{ item.targetTitle }} -->
                <a-tag :key="item.targetType"
                  :color="item.targetType === 'ARTICLE' ? 'green' : item.targetType === 'VOD' ? 'orange' : item.targetType === 'COMMUNITY_POST' ? 'pink' : 'blue'">
                  <span v-if="item.targetType === 'ARTICLE'">
                    文章
                  </span>
                  <span v-else-if="item.targetType === 'VOD'">
                    点播
                  </span>
                  <span v-else-if="item.targetType === 'COMMUNITY_POST'">
                    社区
                  </span>
                  <span v-else-if="item.targetType === 'IMAGE'">
                    图片
                  </span>
                  <!-- {{ item.targetType }} -->
                </a-tag>
                {{ item.targetTitle }}
              </span>

              <!-- a标签 -->
              <span slot="name" slot-scope="text,item">
                <a @click="contentVisible(item)">{{ text }}</a>
              </span>
            </a-table>

            <!-- 按内容查询框的抽屉 -->
            <a-drawer :title="commentDetailParameter.target_type === 'ARTICLE' ? '文章' : commentDetailParameter.target_type === 'VOD' ? '点播' : commentDetailParameter.target_type === 'COMMUNITY_POST' ? '社区' : '图片'" placement="right" :closable="false" :visible="addVisible"
              :after-visible-change="afterVisibleChange" @close="onClose" width="65%">
              <!-- 抽屉的日期部分 -->
              <!-- :color="item.targetType === 'ARTICLE' ? 'green' : item.targetType === 'VOD' ? 'orange' : item.targetType === 'COMMUNITY_POST' ? 'pink' : 'blue'" -->
              <div class="date">
                <span>日期：</span>
                <a-range-picker @change="ondateChange" :value="createValue"/>
                <a-button type="primary">查询</a-button>
              </div>
              <!-- 抽屉的数据展示部分 -->
              <a-table :columns="tableColumns3" :data-source="commentDetail" :pagination="false"
                :row-key="(record) => record.id" style="margin-top: 10%;">
                <!-- 评论内容 -->
                <span slot="requestUri" slot-scope="text, item" >
                  <!-- 悬浮框 -->
                  <a-tooltip placement="topLeft">
                    <template slot="title">
                      {{ item.content }}
                    </template>
                    <!-- 内容 -->
                    <div class="multi-ellipsis"  id="multi-ellipsis" v-on:mouseover="tooltiptry"> <span class="span" v-on:mouseover="tooltip">{{ item.content }}</span></div>
                  </a-tooltip>
                </span>
                <!-- 标签tag -->
                <span slot="titles" slot-scope="text,item">
                  <!-- {{ item.targetTitle }} -->
                  <a-tag :key="item.targetType"
                    :color="item.targetType === 'ARTICLE' ? 'green' : item.targetType === 'VOD' ? 'orange' : item.targetType === 'COMMUNITY_POST' ? 'pink' : 'blue'">
                    <span v-if="item.targetType === 'ARTICLE'">
                      文章
                    </span>
                    <span v-else-if="item.targetType === 'VOD'">
                      点播
                    </span>
                    <span v-else-if="item.targetType === 'COMMUNITY_POST'">
                      社区
                    </span>
                    <span v-else-if="item.targetType === 'IMAGE'">
                      图片
                    </span>
                    <!-- {{ item.targetType }} -->
                  </a-tag>
                  {{ item.targetTitle }}
                </span>
                <!-- 评论账号 -->
                <a slot="name" slot-scope="text">{{ text }}</a>
                <span slot="action" slot-scope="text, record">
                  <a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="confirm2Del(record.id)">
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </span>
              </a-table>
              <!-- 分页器 -->
              <div v-if="tabValue === '1'"
                style="background-color: #fff;height: 80px;float: right; width:101%;display: flex;align-items: center;" >
               <div style="width:100%;display: flex;align-items: center;justify-content: space-between;">
                <span
                  style="font-size: medium;color: #929292;margin-left: 3%;font-size: 14px;  bottom: 3%;">
                  共{{ pagi3nation.total }}条记录 第 {{ btn3Change.page_no }} / {{ Math.ceil(pagi3nation.total /
                    btn3Change.page_size)
                  }} 页
                </span>
                <a-pagination style="float: right;margin-right: 4%" :total=pagi3nation.total show-quick-jumper :page-size.sync="pagexxxSize"
                  @showSizeChange="page3Change" @change="page3Change" show-size-changer class="pagination" />
                </div>
              </div>
            </a-drawer>
            <!--第一个分页符跳 -->
            <div
              style="background-color: #fff;background-color: rgb(255, 255, 255);height: 80px; width:100%">
              <!-- <a-pagination style="float: right;margin-top: 28px" show-quick-jumper show-size-changer :total="90"@change="page2Change" v-model="pagi2nation.current" /> -->
              <span
                style="font-size: medium;color: #929292;float: left;margin-top: 29px;margin-left: 3%;font-size: 14px;">
                共{{ pagination.total }}条记录 第 {{ btnChange.page_no }} / {{ Math.ceil(pagination.total /
                  btnChange.page_size)
                }} 页
              </span>
                <!-- <a-pagination v-model="current" show-size-changer :page-size.sync="pageSize" :total="500" @showSizeChange="onShowSizeChange" /> -->
              <a-pagination style="float: right;margin-top: 28px" show-quick-jumper show-size-changer :page-size.sync="pagehhhSize"
                :total=pagination.total v-if="tabValue === '1'" @change="pageChange" @showSizeChange="pageChange"
                v-model="pagination.current"  />
              <!-- <a-pagination style="float: right;margin-top: 28px" @showSizeChange="onShowSizeChange" @change="onShowSizeChange" show-quick-jumper show-size-changer :pageSize=btnChange.page_size/> -->
            </div>
            </div>  
        </a-spin> 
      </div>
                <!-- 按评论查询框 -->
                <div class="HotContainer" v-if="tabValue === '2'" style="background-color: #fff;">
                  <a-spin :spinning="spinning">
                    <div class="hotTable">
                      <a-table :columns="tableColumns2" :data-source="commentData" :pagination="false"
                        :row-key="(record) => record.id"
                        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                        <!-- 评论内容 -->
                        <span slot="requestUri" slot-scope="text, item">
                          <!-- 悬浮框 -->
                          <a-tooltip placement="topLeft">
                            <template slot="title">
                              {{ item.content }}
                            </template>
                            <!-- 内容 -->
                            <div class="multi-ellipsis"> <span class="span2" >{{ item.content }}</span></div>
                            <!-- <div class="multi-ellipsis" v-on:mouseover="tooltip" id="multi-ellipsis"> <span class="span">{{ item.content }}</span></div> -->
                          </a-tooltip>
                        </span>
                        <!-- 标签tag -->
                        <span slot="titles" slot-scope="text,item">
                          <!-- {{ item.targetTitle }} -->
                          <a-tag :key="item.targetType"
                            :color="item.targetType === 'ARTICLE' ? 'green' : item.targetType === 'VOD' ? 'orange' : item.targetType === 'COMMUNITY_POST' ? 'pink' : 'blue'">
                            <span v-if="item.targetType === 'ARTICLE'">
                              文章
                            </span>
                            <span v-else-if="item.targetType === 'VOD'">
                              点播
                            </span>
                            <span v-else-if="item.targetType === 'COMMUNITY_POST'">
                              社区
                            </span>
                            <span v-else-if="item.targetType === 'IMAGE'">
                              图片
                            </span>
                            <!-- {{ item.targetType }} -->
                          </a-tag>
                          {{ item.targetTitle }}
                        </span>
                        <!-- 评论账号 -->
                        <span slot="name" slot-scope="text,item">
                          <a @click="CommentDrawer(item)">{{ text }}</a>
                        </span>
                        <!-- 删除按钮 批量删除 -->
                        <span slot="action" slot-scope="text, record">
                          <a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="confirmDel(record.id)">
                            <a href="javascript:;">删除</a>
                          </a-popconfirm>
                        </span>
                      </a-table>
                      <!-- 按评论查询框的抽屉22222222222222 -->
                      <a-drawer :title=accountDetailsParameter.name placement="right" :closable="false" :visible="CommentVisible"
                        :after-visible-change="afterVisibleChange" @close="CommentOnClose" width="65%">
                        <div class="date">
                          <span>日期：</span>
                          <a-range-picker @change="ondate2Change" :value="create2Value"/>
                          <a-button type="primary">查询</a-button>
                        </div>
                        <!-- 抽屉的数据展示部分222222222222222222 -->
                        <a-table :columns="tableColumns4" :data-source="accountDetails" :pagination="false"
                          :row-key="(record) => record.id" style="margin-top: 10%;">
                          <!-- 评论内容 -->
                          <span slot="requestUri" slot-scope="text, item">
                            <!-- 悬浮框 -->
                            <a-tooltip placement="topLeft">
                              <template slot="title">
                                {{ item.content }}
                              </template>
                              <!-- 内容 -->
                              <div class="multi-ellipsis">{{ item.content }}</div>
                            </a-tooltip>
                          </span>
                          <!-- 标签tag -->
                          <span slot="titles" slot-scope="text,item">
                            <!-- {{ item.targetTitle }} -->
                            <a-tag :key="item.targetType"
                              :color="item.targetType === 'ARTICLE' ? 'green' : item.targetType === 'VOD' ? 'orange' : item.targetType === 'COMMUNITY_POST' ? 'pink' : 'blue'">
                              <span v-if="item.targetType === 'ARTICLE'">
                                文章
                              </span>
                              <span v-else-if="item.targetType === 'VOD'">
                                点播
                              </span>
                              <span v-else-if="item.targetType === 'COMMUNITY_POST'">
                                社区
                              </span>
                              <span v-else-if="item.targetType === 'IMAGE'">
                                图片
                              </span>
                              <!-- {{ item.targetType }} -->
                            </a-tag>
                            {{ item.targetTitle }}
                          </span>
                          <!-- 评论账号 -->
                          <a slot="name" slot-scope="text,item">{{ text }}</a>
                          <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
                          <!-- 删除按钮 -->
                          <span slot="action" slot-scope="text, record">
                            <a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="confirm3Del(record.id)">
                              <a href="javascript:;">删除</a>
                            </a-popconfirm>
                          </span>
                        </a-table>
                        <!-- 分页器 -->
                        <div v-if="tabValue === '2'"
                          style="background-color: #fff;background-color: rgb(255, 255, 255);height: 80px;float: right; width:100%;display: flex;align-items: center;"
                          class="pagination">
                          <div style="display: flex;justify-content:space-between;width: 100%;align-items: center">
                          <span style="font-size: medium;color: #929292;font-size: 14px; ">
                            共{{ pagi4nation.total }}条记录 第 {{ btn4Change.page_no }} / {{ Math.ceil(pagi4nation.total /
                              btn4Change.page_size)
                            }} 页
                          </span>
                          <a-pagination style="float: right;" :total=pagi4nation.total show-quick-jumper v-model="pagi4nation.current" :page-size.sync="pagezzzSize"
                            @showSizeChange="page4Change" @change="page4Change" show-size-changer id="pagination2" />
                          <!-- <span style="font-size: medium;color: #929292">共 {{videoCountLive}} 条记录 第 {{ videoPageNoLive }} / {{ Math.ceil(videoCountLive / videoPageSizeLive) }} 页</span> -->
                        </div>
                        </div>
                      </a-drawer>
                      <div v-if="tabValue === '2'"
                        style="background-color: #fff;background-color: rgb(255, 255, 255);height: 80px; width:100%">
                        <span
                          style="font-size: medium;color: #929292;float: left;margin-top: 29px;margin-left: 3%;font-size: 14px;">
                          共{{ pagi2nation.total }}条记录 第 {{ btn2Change.page_no }} / {{ Math.ceil(pagi2nation.total /
                            btn2Change.page_size)
                          }} 页
                        </span>
                        <!-- 第二个分页符跳 -->
                        <a-pagination style="float: right;margin-top: 28px" show-quick-jumper show-size-changer :page-size.sync="pagelllSize"
                          :total=pagi2nation.total @change="page2Change" v-model="pagi2nation.current"
                          @showSizeChange="page2Change" />
                      </div>
                    </div>
                  </a-spin>
                </div>
              </a-spin>
            </div>
            <!-- <span style="font-size: medium;color: #929292">共 {{videoCountLive}} 条记录 第 {{ videoPageNoLive }} / {{ Math.ceil(videoCountLive / videoPageSizeLive) }} 页</span> -->
</template>
<script>
import {
  getContentData, getCommentData, getCommentDetail, getAccountDetails, delComment, BulkDeletion
} from "@/service/systemApi"
import { getDocument } from '../../../service/document_api';
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width: '30%',
    scopedSlots: { customRender: 'name' },
    ellipsis: true,
    // fixed: 'left'
  }, {
    title: '专家',
    dataIndex: 'expertName',
    key: 'expertName',
    // scopedSlots: { customRender: 'expertName' },
    width: '20%',
  }, {
    title: '上传时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    // ellipsis: true,
  }, {
    title: '创建人',
    dataIndex: 'person',
    key: 'person',
  }, {
    title: '动态',
    dataIndex: 'isOpen',
    key: 'isOpen',
    scopedSlots: { customRender: 'isOpen' },
  },
  {
    title: '关联大会',
    scopedSlots: { customRender: 'conCount' },
  }, {
    title: '关联栏目',
    scopedSlots: { customRender: 'colCount' },
  },
  {
    title: '操作',
    key: 'action',
    width: 200,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];
const columns_model = [
  {
    title: '标题',
    dataIndex: 'itemTitle',
    key: 'itemTitle',
    width: '30%',
    ellipsis: true,
    scopedSlots: { customRender: 'itemTitle' },
  }, {
    title: '专家',
    dataIndex: 'expertName',
    key: 'expertName',
    scopedSlots: { customRender: 'hospital' },
    width: '30%',
  }, {
    title: '上传时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    width: '15%',
  }, {
    title: '创建人',
    dataIndex: 'createdName',
    key: 'createdName',
    width: '10%',
  }, {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    width: '10%',
    scopedSlots: { customRender: 'sort' },
  }, {
    title: '操作',
    key: 'action',
    width: '10%',
    scopedSlots: { customRender: 'action' },
  },
];
const columns_video = [
  {
    title: '封面图',
    dataIndex: 'coverImg',
    key: 'coverImg',
    scopedSlots: { customRender: 'img' },
  }, {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '专家',
    dataIndex: 'expertName',
    key: 'expertName',
    scopedSlots: { customRender: 'hospital' },
    width: '30%',
  }, {
    title: '操作',
    key: 'action',
    width: '20%',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'videoOnDemand',
  // name: 'HotSearchWords',
  data() {
    return {
      createValue: [],//日期框的显示
      create2Value: [],//日期框的显示
      pagehhhSize: 10,  //用于重置 10条/页
      pagelllSize: 10,
      pagexxxSize: 10,
      pagezzzSize:10,
      // listPageNo: 1,
      total: 200,
      pageSizeOptions: ['10', '20', '30', '40'],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      pagi2nation: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      pagi3nation: {
        total: 0,
      },
      pagi4nation: {
        total: 0,
        current: 1,
      },

      visible: false,
      spinning: false,
      // 搜索区域绑定对象
      searchList: {
        keyword: '' // 关键字
      },
      addVisible: false,
      CommentVisible: false,
      searchValue: '',
      // current: 1,
      total: 1,
      pageSize: 10,
      tableColumns: [
        {
          title: '内容标题',
          dataIndex: 'targetTitle',
          scopedSlots: { customRender: 'titles' },
          width: '40%'
        },
        {
          title: '内容作者',
          dataIndex: 'name',
          width: '20%'
        },
        {
          title: '评论条数',
          dataIndex: 'count',
          width: '20%',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '发布时间',
          dataIndex: 'createdTime',
          width: '20%'
        },
      ],
      tableColumns2: [
        {
          // title: '全选',
          // dataIndex: 'keyword',
          width: '1%'
        },
        {
          title: '评论内容',
          dataIndex: 'content',
          width: '16%',
          scopedSlots: { customRender: 'requestUri' }
        },
        {
          title: '评论时间',
          dataIndex: 'createdTime',
          width: '19%'
        },
        {
          title: '评论账号',
          dataIndex: 'name',
          width: '13%',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '内容标题',
          dataIndex: 'targetTitle',
          width: '42%',
          scopedSlots: { customRender: 'titles' },
        },
        {
          title: '操作',
          // dataIndex: 'frequency',
          width: '8%',
          scopedSlots: { customRender: 'action' },
        },
      ],
      tableColumns3: [
        {
          title: '发言内容',
          dataIndex: 'content',
          width: '10%',
          scopedSlots: { customRender: 'requestUri' }
        },
        {
          title: '发言时间',
          dataIndex: 'createdTime',
          width: '20%'
        },
        {
          title: '发言人',
          dataIndex: 'name',
          width: '18%',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '操作',
          // dataIndex: 'frequency',
          width: '8%',
          scopedSlots: { customRender: 'action' },
        },
      ],
      tableColumns4: [
        {
          title: '发言内容',
          dataIndex: 'content',
          width: '14%',
          scopedSlots: { customRender: 'requestUri' }
        },
        {
          title: '发言时间',
          dataIndex: 'createdTime',
          width: '13%'
        },
        {
          title: '内容标题',
          dataIndex: 'targetTitle',
          width: '21%',
          scopedSlots: { customRender: 'titles' },
        },
        {
          title: '操作',
          // dataIndex: 'frequency',
          width: '8%',
          scopedSlots: { customRender: 'action' },
        },
      ],

      tableData: [],
      commentData: [],
      commentDetail: [],
      accountDetails: [],

      searchName: '', //a-input的内容
      search2Name: '',

      btnChange: {   //传按钮的参数
        page_no: 1,
        page_size: 10,
        total: 0
      },
      btn2Change: {   //传按钮的参数
        page_no: 1,
        page_size: 10,
        total: 0
      },
      btn3Change: {   //传按钮的参数
        page_no: 1,
        page_size: 10,
        total: 0
      },
      btn4Change: {   //传按钮的参数
        page_no: 1,
        page_size: 10,
        total: 0
      },
      user_id: '',  //被选中的id
      selectedRowKeys: [],
      commentDetailParameter: {
        target_type: '',
        target_id: '',
        page_num: 1,
        page_size: 10,
        startTime: "",
        endTime: "",
      },
      accountDetailsParameter: {
        name: '',
        user_id: '',
        page_num: 1,
        page_size: 10,
        startTime: "",
        endTime: "",
      },


      pageNum: 1,
      pageSize: 10,
      total: 0,
      spinning: false,
      data: [],
      titleVal: '',

      videoPageNo: 1,
      videoPageSize: 10,
      data_model_count_video: 0,

      videoPageNoLive: 1,
      videoPageSizeLive: 10,
      videoCountLive: 1,

      vodPageNo: 1,
      vodPageSize: 10,
      data_model: [],
      data_model_count: 0,
      columns,
      columns_model,
      columns_video,
      confirmChoices: '',
      confirmChoicesValue: '',
      yearShowOne: false,
      // year:current_date(),
      year: '',
      videoTitle: '',
      selectId: [],
      liveId: [],
      leftLiveList: [],
      keyWordVod_pageNo: 1,
      keyWordVod_pageSize: 10,
      expertName: '',
      listId: null,
      leftTemplete: [],
      leftTempletes: [],


      stateType: "",
      stateType2: "",
      stateTypeList: [
        {
          title: '全部',
          state: ''
        }, {
          title: '点播',
          state: 'VOD'
        }, {
          title: '文章',
          state: 'ARTICLE'
        }, {
          title: '社区',
          state: 'COMMUNITY_POST'
        },
      ],
      stateTypeList2: [
        {
          title: '全部',
          state: ''
        }, {
          title: '点播',
          state: 'VOD'
        }, {
          title: '文章',
          state: 'ARTICLE'
        }, {
          title: '社区',
          state: 'COMMUNITY_POST'
        },
      ],
      labelCol: { span: 4 },
      labelColLive: { span: 5 },
      wrapperCol: { span: 14 },
      editor: null,
      editor_menus: [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        'fullscreen' // 全屏
      ],
      visible_Check_video: false,
      // CaseLiveList:[],
      // CaseLiveList_page_no:1,
      // CaseLiveList_title:'',
      oneList: {},
      selectHosModel: undefined,
      videoNum: 0,
      vodId: null,
      selectHosList: [],
      selectHosListVideo: [],
      selectHosList_page_no: 1,
      selectHosList_title: '',
      search_model: '',
      article: '',
      keyWordVod: [],
      keyWordArticle: [],
      keyWordVodIsShow: false,
      promotionsVisible: false,
      appColumns: [
        {
          title: 'APP首页',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      appData: [],
      columnListApp: [],
      promotionId: null,
      subContentType: null,
      promotionSpin: false,
      communityColumns: [
        {
          title: '社区',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      communityData: [
        {
          id: 1,
          title: '首页',
          is_open: false
        }
      ],
      // communityCode:'', 
      visible_add_video: false,
      tabValue: '1',


      videoTitleVod: '',
      ColumnSelection: false,
      manageId: null,
      columnCode: [],

      listIdA: null,
    }
  },
  created() {
    // this.$store.dispatch('setManageHeaderTitle', "热搜词管理")
    this.getContentData()
    this.getCommentData()
    // this.getCommentDetail()
    // this.getAccountDetails()
  },
  methods: {
    // 字数超出显示tooltip(第一个tooltip)
    tooltip(e) {
      // // const e = this.$refs[e]
      // const parentWidth = e.parentNode.offsetWidth // 获取元素父级可视宽度
      // const contentWidth = e.offsetWidth // 获取元素可视宽度

      // console.log("父",multiEllipsis.offsetWidth)
      // console.log(e)

      var span = document.querySelector('.span')
      var multiEllipsis = document.querySelector('#multi-ellipsis')

      // console.log("子", span.scrollWidth)
      var gan = document.querySelector('.gan')
      // console.log("子", gan.offsetTop)

      // if (span.offsetWidth < 95) {
      //   e.target.style.pointerEvents = "none"; // 阻止鼠标事件
      // }

      // if (contentWidth >= parentWidth) {
      //   e.target.style.pointerEvents = "none"; // 阻止鼠标事件
      // }
    },

    tooltiptry(e) {
      // console.log("父", e)
      // console.log("父",multiEllipsis.offsetWidth)
      var multiEllipsis = document.querySelector('#multi-ellipsis')
      // console.log("父",multiEllipsis.scrollWidth)

    },

  //   onMouseOver(str) { // 内容超出，显示文字提示内容
  //     const tag = this.$refs[str]
  //     const parentWidth = tag.parentNode.offsetWidth // 获取元素父级可视宽度
  //     const contentWidth = tag.offsetWidth // 获取元素可视宽度
  //     this.isShowTooltip = contentWidth <= parentWidth  
  //     // 鼠标悬停后显示的内容
  //     this.contentHover = this.name
  // },

    tooltip2(e) {
      console.log(e)
      var span2 = document.querySelector('.span2')
      console.log(span2.clientWidth)
      // var multiEllipsis = document.querySelector('#multi-ellipsis')

      if (span2.offsetWidth < 100) {
        e.target.style.pointerEvents = "none"; // 阻止鼠标事件
      }
    },


    // 按内容查询重置按钮
    returnBtn() {
      this.stateType = ""
      this.searchName = ''
      this.pagination.current = 1
      this.pagehhhSize = 10

      this.btnChange.page_no = 1
      this.btnChange.page_size = 10

      this.getContentData("", '', 1, 10)
    },
    // 按评论查询重置按钮
    return2Btn() {
      this.pagelllSize = 10
      this.stateType2 = ""
      this.search2Name = ''
      this.selectedRowKeys = []
      this.pagi2nation.current = 1

      this.btn2Change.page_no = 1
      this.btn2Change.page_size = 10

      this.getCommentData("", '', 1, 10)

    },
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    onClose() {
      this.addVisible = false;
      this.commentDetailParameter.startTime = ''
      this.commentDetailParameter.endTime = ''
      this.createValue = []
      this.pagexxxSize = 10
    },
    onChange(date, dateString) {
      // console.log(date, dateString);
    },
    CommentOnClose() {
      this.CommentVisible = false;
      this.accountDetailsParameter.startTime = ''
      this.accountDetailsParameter.endTime = ''
      this.create2Value = []
      this.pagezzzSize = 10
    },
    // 删除功能(<按评论的那一边的>)
    async confirmDel(id) {
      // console.log(id)
      // this.$message.success('删除成功！')
      const response = await delComment(id)
      if (response.code === 0) {
        this.$message.success('删除成功！')
        // 刷新
        await this.getCommentData(this.target_type, this.content, this.page_num, this.page_size)
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },

    // 删除功能(<按内容的抽屉里>)
    async confirm2Del(id) {
      // console.log(id)
      const response = await delComment(id)
      if (response.code === 0) {
        this.$message.success('删除成功！')
        await this.getCommentDetail(this.target_id, this.target_type, this.page_num, this.page_size, this.startTime, this.endTime)
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },

    // 删除功能(<按评论的抽屉里>)
    async confirm3Del(id) {
      // console.log(id)
      const response = await delComment(id)
      if (response.code === 0) {
        this.$message.success('删除成功！')
        await this.getAccountDetails(this.user_id, this.page_num, this.page_size, this.startTime, this.endTime)
      } else {
        this.$message.warning("出错，请联系管理员", response.message)
      }
    },

    //获取id
    onSelectChange(selectedRowKeys) {
      // this.user_id = ''
      //选择的列表的id
      this.user_id = selectedRowKeys
      console.log(this.user_id)
      //显示是否被选中
      this.selectedRowKeys = selectedRowKeys;
    },
    // 多选批量删除
    async delConfirm() {
      var memberIds = this.user_id
      // console.log(888, memberIds)
      if (memberIds) {
        const response = await BulkDeletion(memberIds)
        console.log(5555555, memberIds)
        if (response.code === 0) {
          //删除成功后， 刷新界面
          const response = this.searchForm
          await this.getCommentData(this.target_type, this.content, this.page_num, this.page_size)
          // 清空选项
          this.user_id = ''
          this.selectedRowKeys = []
          this.$message.success('删除成功！')
        } else {
          this.$message.warning('出错' + response.message)
        }
      } else {
        this.$message.success('请先选择需要删除的列表！')
      }

    },

    // 1.1向getContentData传模块的参数（vod...）
    typeChange(value) {
      this.stateType = value
      // 重置页码和分页数 以及current
      this.pagehhhSize = 10
      this.btnChange.page_no = 1
      this.pagination.total = this.pagination.total
      this.btnChange.page_size = 10
      // this.searchName = ''
      this.pagination.current = 1

      // console.log(this.stateType)
      this.getContentData()
    },
    // 1.2向getContentData传内容标题关键字的参数
    searchBtn() {
      // 点击查询的时候不要管页码的参数
      this.btnChange.page_no = 1;
      this.btnChange.page_size = 10;
      this.pagination.current = 1
      this.getContentData('', this.searchName, 1, 10)
    },
    //1.3向getContentData传页码和行数的参数
    pageChange(page_no, page_size) {
      // console.log(page_size_options)  
      this.btnChange.page_no = page_no
      this.btnChange.page_size = page_size
      // console.log(this.btnChange.page_no)
      // console.log(this.btnChange.page_size)
      this.getContentData()
    },
    // // 按内容查询点10条/页 触发  可以通过page变接口的数据，但是无非重置回去
    // tableChange(pageNumber, page_size) {
    //   // this.pagination.total = this.pagination.total
    //   // this.pagehhhSize = 10
    //   this.btnChange.page_size = page_size
    //   this.getContentData()
    //   // console.log(this.btnChange.page_no)
    // },
    // 1 获取按照内容查询列表
    async getContentData(target_type, target_title, page_num, page_size) { // 获取按照内容查询列表
      var target_type = this.stateType; var target_title = this.searchName; var page_num = this.btnChange.page_no; var page_size = this.btnChange.page_size
      const response = await getContentData(target_type, target_title, page_num, page_size)
      if (response.code === 0) {
        this.tableData = response.data
        this.pagination.total = response.count
        // console.log('159143', this.tableData)
      } else {
        this.$message.warning(response.message)
      }
    },

    // 2.1向getCommentData传模块的参数（vod...）
    typeChange2(value) {
      // 重置页码和分页数  输入框以及current
      this.pagelllSize = 10
      this.btn2Change.page_no = 1
      this.btn2Change.page_size = 10
      // this.search2Name = ''
      this.pagi2nation.current = 1
      // this.pageSizeOptions = '10'

      this.stateType2 = value
      // console.log(this.stateType2)
      this.getCommentData()
    },
    // 2.2向getCommentData传内容标题关键字（input）的参数
    search2Btn() {
      // 重置页码和页数
      this.btn2Change.page_no = 1;
      this.btn2Change.page_size = 10;
      this.pagi2nation.current = 1
      this.getCommentData(this.stateType, this.searchName, this.btn2Change.page_no, this.btn2Change.page_size)
    },
    //2.3向getContentData传页码和行数的参数
    page2Change(page_no, page_size) {
      this.btn2Change.page_no = page_no
      this.btn2Change.page_size = page_size
      // console.log(this.btn2Change.page_no)
      // console.log(this.btn2Change.page_size)
      // 按键修改值，然后上面统一传递
      this.getCommentData()
    },

    // 按评论查询点10条/页 触发
    table2Change(pageNumber, page_size) {
      this.btn2Change.page_size = page_size
      this.getCommentData()
    },

    async getCommentData(target_type, content, page_num, page_size) {  //获取按照评论查询查询列表
      var target_type = this.stateType2; var content = this.search2Name; var page_num = this.btn2Change.page_no; var page_size = this.btn2Change.page_size
      const response = await getCommentData(target_type, content, page_num, page_size)
      if (response.code === 0) {
        this.commentData = response.data
        this.pagi2nation.total = response.count
        // console.log('1111112232212', this.commentData)
        // console.log("111", this.commentData)
        // this.total = response.count
      } else {
        this.$message.warning(response.message)
      }
    },

    //3.1向getCommentData传模块和target_id的参数（vod...）
    contentVisible(item) {
      // console.log(item)
      // 给getCommentDetail传target_type参（评论条数，数字那个）
      this.commentDetailParameter.target_type = ''   //清空
      this.commentDetailParameter.target_type = item.targetType
      // 传target_id参
      this.commentDetailParameter.target_id = ''
      this.commentDetailParameter.target_id = item.targetId
      // 重置页码和行数
      this.btn3Change.page_no = 1
      this.btn3Change.page_size = 10

      this.getCommentDetail()
      this.addVisible = true;
    },
    // 3.2向getCommentData传page的参数startTime，endTime
    page3Change(page_no, page_size) {
      this.btn3Change.page_no = page_no
      this.btn3Change.page_size = page_size
      // console.log(this.btn3Change.page_size)
      // console.log(this.btn3Change.page_no)
      // // 按键修改值，然后上面统一传递
      this.getCommentDetail()
    },
    //3.3向getCommentDetail传日期参数
    async ondateChange(date, dateString) {
      this.createValue = date
      this.commentDetailParameter.startTime = dateString[0]
      this.commentDetailParameter.endTime = dateString[1]
      // var value3 = (moment(date).format('YYYY-MM-DD'))
      // var value3 = (moment(dateString).format('YYYY-MM-DD'))
      // const newVal = this.value3
      // console.log(newVal)
      this.getCommentDetail()
    },

    // table3Change(pageNumber, page_size) {
    //   // console.log(pageNumber)
    //   this.btn3Change.page_size = page_size
    //   this.getCommentDetail()
    //   console.log(this.btn3Change.page_no)
    // },

    // 3 getCommentDetail //获取评论详情列表
    async getCommentDetail(target_id, target_type, page_num, page_size, startTime, endTime) {  //获取评论详情列表
      var target_id = this.commentDetailParameter.target_id; var target_type = this.commentDetailParameter.target_type; var page_num = this.btn3Change.page_no; var page_size = this.btn3Change.page_size; startTime = this.commentDetailParameter.startTime; endTime = this.commentDetailParameter.endTime
      const response = await getCommentDetail(target_id, target_type, page_num, page_size, startTime, endTime)
      // console.log(target_id, target_type, page_num, page_size, this.commentDetailParameter.startTime, this.commentDetailParameter.endTime)
      if (response.code === 0) {
        this.commentDetail = response.data
        this.pagi3nation.total = response.count
        // console.log("14771213282", response.data)
      } else {
        this.$message.warning(response.message)
      }
    },
    // 4.1向getCommentData传user_id参数
    CommentDrawer(item) {
      // 获取name
      this.accountDetailsParameter.name = ''
      this.accountDetailsParameter.name = item.name
      // console.log(this.accountDetailsParameter.name)
      // 获取userid
      this.accountDetailsParameter.user_id = ''
      this.accountDetailsParameter.user_id = item.userId
      // console.log(888888, this.accountDetailsParameter.user_id)
      // 重置页码和行数
      this.btn4Change.page_no = 1
      this.pagi4nation.current = 1
      // this.btn4Change.page_size = 10

      this.getAccountDetails()
      this.CommentVisible = true;
    },
    // 4.2向getCommentData传page参数
    page4Change(page_no, page_size) {
      // console.log("是可以的哦")
      this.btn4Change.page_no = page_no
      this.btn4Change.page_size = page_size
      // console.log(this.btn4Change.page_size)
      // console.log(this.btn4Change.page_no)
      // // 按键修改值，然后上面统一传递
      this.getAccountDetails()
    },
    //4.3向getCommentDetail传日期参数
    async ondate2Change(date, dateString) {
      this.create2Value = date
      this.accountDetailsParameter.startTime = dateString[0]
      this.accountDetailsParameter.endTime = dateString[1]
      // console.log(this.accountDetailsParameter.startTime, this.accountDetailsParameter.endTime)
      this.getAccountDetails()
    },

    table4Change(pageNumber, page_size) {
      // console.log(pageNumber)
      this.btn4Change.page_size = page_size
      this.getAccountDetails()
    },
    // 4 获取账号详情列表
    async getAccountDetails(user_id, page_num, page_size, startTime, endTime) {  //获取账号详情列表
      if (this.accountDetailsParameter.startTime == '' && this.accountDetailsParameter.endTime == '') {
        this.accountDetailsParameter.startTime == "2020-06-01";
        this.accountDetailsParameter.endTime == "2020-09-01"
      }
      var user_id = this.accountDetailsParameter.user_id; var page_num = this.btn4Change.page_no; var page_size = this.btn4Change.page_size; var startTime = this.accountDetailsParameter.startTime; var endTime = this.accountDetailsParameter.endTime
      // console.log(user_id, page_num, page_size, startTime, endTime)
      const response = await getAccountDetails(user_id, page_num, page_size, startTime, endTime)
      if (response.code === 0) {
        this.accountDetails = response.data
        this.pagi4nation.total = response.count
        // console.log("6666", response.data)
      } else {
        this.$message.warning(response.message)
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.HotContainer {
  margin: 20px -15px 0 -15px;

  .header {
    display: flex;
    padding-bottom: 20px;
    padding-left: 20px;
    border-bottom: 15px solid rgba(240, 242, 245, 1);
  }

  .hotTable {
    padding: 20px;
  }
}

.video-top {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;

  .input {
    width: 65%;
    margin-left: 10px;
  }
}

.span2{
  // background-color: pink;
}

.video-tab {
  width: 100%;
  //height: 60px;
  border-top: 10px solid #f0f2f5;
}

.video-content {
  width: 100%;
  min-height: 600px;
  border-top: 10px solid #f0f2f5;
  display: flex;

  .video-content-l {
    width: 20%;
    height: auto;
  }

  .video-content-r {
    width: 80%;
    height: auto;
    border-left: 13px solid #f0f2f5;

    .r-btn {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .b-btn {
      margin: 10px;
    }
  }
}

.span{
  // background-color: pink;
}

.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.upload_LiveBroadcastL {
  border: #DDDDDD 2px dashed;
  width: 240px;
  height: 65px;
  margin-left: 20px;
  text-align: center;
}

.upload_LiveBroadcastX {
  border: #DDDDDD 2px dashed;
  width: 240px;
  height: 135px;
  margin-left: 20px;
  text-align: center;
}

.addVideo {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
}

.specialCatalog {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
  border-top: 1px solid #e7e7e7;
}

.child-content {
  margin-top: 15px;
}

.date {
  margin: auto;
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.drawer {
  // background-color: #f5f5f5;
}

.pagination {
  // position: absolute;
  // right: 0;
  bottom: 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 超出一行则以...形式隐藏
  -webkit-box-orient: vertical;
  cursor: pointer;
  // background-color: green;
}
</style>
