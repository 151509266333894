import {
  get,
  put,
  post,
  del,
  postUserId,
  putUserId,
  delUserId,
  postXtoken,
  delXtoken,
} from "./base_y";
import qs from "qs";

// 个人中心管理

// 获取个人信息功能列表
export function getPersonal(data) {
  return get("/v3/yun/person/funcs", data);
}

// 上移下移个人功能
export function postPersonalUpDown(id, data) {
  return postUserId("/v3/yun/person/func/moveUpDown/" + id, qs.stringify(data));
}

// 新增个人功能
export function postPersonalAdd(data) {
  return postUserId("/v3/yun/person/func", data);
}

// 删除/编辑个人功能
export function putPersonalEditRemove(id, type, data) {
  return putUserId("/v3/yun/person/func?id=" + id + "&type=" + type, data);
}

// 热搜词管理

// 热搜词查询
export function getHotSearch(pageNo, pageNum, data) {
  return get(
    "/v3/yun/admin/search?pageNo=" +
      pageNo +
      "&pageNum=" +
      pageNum +
      "&search=" +
      data
  );
}

// 评论管理

// 按内容查询评论
export function getContentData(target_type, target_title, page_num, page_size) {
  return get(
    `/v3/comment/contentList?target_type=${target_type}&target_title=${target_title}&page_num=${page_num}&page_size=${page_size}`
  );
}
// 按评论去查询
export function getCommentData(target_type, content, page_num, page_size) {
  return get(
    `/v3/comment/commentList?target_type=${target_type}&content=${content}&page_num=${page_num}&page_size=${page_size}`
  );
}
// 评论详情去查询
export function getCommentDetail(
  target_id,
  target_type,
  page_num,
  page_size,
  startTime,
  endTime
) {
  return get(
    `/v3/comment/contentDetails?target_id=${target_id}&target_type=${target_type}&page_num=${page_num}&page_size=${page_size}&startTime=${startTime}&endTime=${endTime}`
  );
}
// 账号详情去查询
export function getAccountDetails(
  user_id,
  page_num,
  page_size,
  startTime,
  endTime
) {
  return get(
    `/v3/comment/accountDetails?user_id=${user_id}&page_num=${page_num}&page_size=${page_size}&startTime=${startTime}&endTime=${endTime}`
  );
}
// 删除
export function delComment(id) {
  return del("/v3/comment/deleteCommentById", { id });
}
//批量删除
export function BulkDeletion(ids) {
  return del("/v3/comment/deleteCommentByIds", { ids });
}

// export function delMagazines(ids){
//     return del('/api/admin/magazine',{ids})
// }
// export function delAdmin(memberIds){
//     return del('/api/admin/zk/member', {memberIds:memberIds})
// }

// App首页功能管理

// 获取App首页功能
export function getEnableFunction(data) {
  return get("/v3/yun/moudle/funcs", data);
}

// 上移下移App首页功能
export function postEnableUpDown(id, data) {
  return postUserId("/v3/yun/moudle/func/moveUpDown/" + id, qs.stringify(data));
}

// 修改App首页功能
export function putEnableUpdate(id, data) {
  return putUserId("/v3/yun/moudle/func/" + id, data);
}

// 新增App首页功能
export function postEnableAdd(data) {
  return postUserId("/v3/yun/moudle/func", data);
}

// 删除App首页功能
export function deleteEnableRemove(id) {
  return delUserId("/v3/yun/moudle/func/" + id);
}

// 社区首页功能管理

// 获取社区首页功能
export function getCommunity(data) {
  return get("/v3/yun/community/funcs", data);
}

// 新增社区首页功能
export function postCommunityAdd(data) {
  return postUserId("/v3/yun/community/func", data);
}

// 上移下移社区首页功能
export function postCommunityUpDown(id, type) {
  return postUserId(
    "/v3/yun/community/func/moveUpDown/" + id,
    qs.stringify(type)
  );
}

// 编辑/删除社区首页功能
export function putCommunityUpDelete(id, type, data) {
  return putUserId("/v3/yun/community/func?id=" + id + "&type=" + type, data);
}

// H5链接设置

// 查询H5链接设置
export function getH5Link(id) {
  return get("/v3/yunHtmlUrl?hid=" + id);
}

// 新增H5链接
export function postH5LinkAdd(data) {
  return post("/v3/yunHtmlUrl", data);
}

// 删除H5链接
export function putH5LinkDelete(id) {
  return put("/v3/yunDelHtmlUrl/" + id);
}

// 修改H5链接
export function putH5LinkUpdate(id, data) {
  return put("/v3/yunUpdHtmlUrl/" + id, data);
}

// 意见反馈管理

// 查询意见反馈
export function postFeedback(data) {
  return get("/v3/yun/admin/feedback", data);
}

// 回复意见反馈
export function putFeedbackReply(data) {
  return putUserId("/v3/yun/admin/feedback", data);
}

// 用户投诉管理

// 处理用户投诉
export function putUserComplaintsHandle(data) {
  return putUserId("/v3/yun/admin/complain", data);
}

// 查询用户投诉列表
export function getUserComplaints(status, id) {
  return get("/v3/yun/admin/complain?state=" + status + "&id=" + id);
}

// banner广告管理

// 查询banner广告管理
export function getHomeBanner(isShow, id, time, notes, pageNo, pageSize) {
  if (id > 0) {
    return get(
      "/v3/yun/platform/banner?isShow=" +
        isShow +
        "&id=" +
        id +
        "&time=" +
        time +
        "&notes=" +
        notes
    );
  } else {
    return get(
      "/v3/yun/platform/banner?isShow=" +
        isShow +
        "&id=" +
        id +
        "&time=" +
        time +
        "&notes=" +
        notes +
        "&pageNo=" +
        pageNo +
        "&pageSize=" +
        pageSize
    );
  }
}

// 上移下移banner广告管理
export function putHomeBannerUpDown(id, move) {
  return putUserId("/v3/yun/platform/banner/" + id + "/" + move);
}

// 上架下架banner广告
export function putHomeBannerUpDownShelf(id) {
  return putUserId("/v3/yun/platform/banner/" + id);
}

// 新增banner广告
export function postHomeBannerAdd(data) {
  return postUserId("/v3/yun/platform/banner", data);
}

// 修改banner广告
export function putHomeBannerEdit(data) {
  return putUserId("/v3/yun/platform/banner", data);
}

// 删除banner广告
export function putHomeBannerDel(id) {
  return put("/v3/yun/platform/banner/del?id=" + id);
}

// 获取推广banner广告
export function getHomeBannerExtension(id) {
  return get("/v3/yun/promote/banner/" + id);
}

// 获取banner首页推广
export function getHomeBannerIndexExtension(code) {
  return get("/v3/yun/promotes", { code });
}

// 开启banner推广
export function postHomeBannerOpenExtension(data) {
  return post("/v3/yun/promote/banner", data);
}

// 关闭banner推广
export function DelHomeBannerCloseExtension(id, data) {
  return del("/v3/yun/promote/" + id, data);
}

//专栏管理
export function getYunColumnApi(data) {
  return get("/v3/yun/column", data);
}

//新增
export function postYunColumnAddApi(data) {
  return post("/v3/yun/column", data);
}

//编辑
export function putYunColumnEditApi(data) {
  return put("/v3/yun/column", data);
}

//上架下架
export function getYunColumnUpDownApi(data) {
  return put("/v3/yun/column/isShow?id=" + data.id);
}

//查询专家专栏
export function getYunColumnZLApi(data) {
  return get("/v3/yun/column/cycle/id", data);
}

//专家人员
export function getYunColumnZLPeopleApi(data) {
  return get("/v3/yun/column/expert", data);
}

//添加专家专栏
export function postYunColumnZLAddApi(data) {
  return post("/v3/yun/column/relation", data);
}

//删除
export function delYunColumnZLRemove(data) {
  return del("/v3/yun/column/cycle/id?id=" + data.id);
}

//上移下移
export function putYunColumnZLUpDown(data) {
  return get("/v3/yun/column/relation/move", data);
}
// web和小程序推广
export function postBannerRecommend(data) {
  return postXtoken("/v3/banner/recommend", data);
}
// 查询web和小程序推广状态
export function getBannerType(bannerLocation, bannerId) {
  return get(`/v3/banner/type/${bannerLocation}/${bannerId}`);
}
// 关闭推广
export function delBannerType(bannerLocation, advCode, bannerId) {
  return delXtoken(`/v3/banner/type/${bannerLocation}/${advCode}/${bannerId}`);
}
